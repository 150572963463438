<template>
  <div class="copyrightdiv">
    <div class="copyright_content">
      <span><a href="https://www.bjshiyi.cn/static/yyzz.png">北京实益拓展科技有限责任公司</a></span><span>|</span> <span><a target="_blank" href="http://beian.miit.gov.cn/">京ICP备14000240号-3</a></span>
      <span>|</span><span><a target="_blank" href="https://www.bjshiyi.cn/static/chubanwu.jpg">新出发京批字第直230186号</a></span>
                <span>|</span>
                <span>
            <a target="_blank" href="https://www.bjshiyi.cn/static/220669.jpg">新出发京零字第海220699号</a>
            </span>
      <span>|</span><span>北京市昌平回龙观龙冠置业大厦B座五层</span><span>|</span><span>联系电话：400-923-1068</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "loginfooter"
}
</script>

<style scoped lang="less">
.copyrightdiv{
  height: 3.3rem;
  background-color: #6DAB68 ;
  width: 100%;
  display: flex;
  justify-content: center;
.copyright_content{

  display: flex;
  flex-direction: row;
  padding-top: 1.2rem;
  padding-bottom: 1rem;

span{
  white-space: nowrap;
  font-size: 16px;
  padding-right: 25px;

  color:#ffffff;
a{
  color:#CBCBCB;
}
}
}
}
</style>