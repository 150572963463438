<template>
  <div class="top_content">
    <div class="contentdiv">
      <div class="leftcontent">
        <div class="logo">
          <img src="../../../static/logo.png" />
        </div>
      </div>
      <div class="wrpCls" style="margin-left: 1.5rem;margin-right: 0.7rem; margin-top: 0.48rem;">

        <a-tabs class="tabss" @change="jump"  :default-active-key="activekey">
          <a-tab-pane v-for="(item) in panels" :key="item.key" :tab="item.title">

          </a-tab-pane>

        </a-tabs>
      </div>
      <div class="wrpCls" style="height: 2.76rem;padding: 0.1rem;">
        <div class="login-form">

          <div class="main" style="display:flex;flex-direction: row;">
        <a-form id="formlogin" autocomplete="off" :form="form" layout="inline"  class="user-layout-login"    >
          <a-form-item >
            <h3 class="title" >
              自学平台
            </h3>


          </a-form-item>
          <a-form-item>
            <a-input  v-decorator="[
          'username',
          { rules: [{ required: true, message: '账号' }] },
        ]"  size="small" type="text" style="margin-left: 0.2rem;"   class="login-input"  placeholder="账号"></a-input>
          </a-form-item>
          <a-form-item>
            <a-input-password v-decorator="[
          'password',
          { rules: [{ required: true, message: '密码' }] },
        ]"  size="small" @keydown.enter="handleSubmit" style="margin-left: 0.20rem;"  class="login-input" type="text" placeholder="密码" ></a-input-password>
          </a-form-item>
          <a-form-item>
            <a-button  size="small" style="align-items: center; border-radius: 0.72rem; font-weight:bold;font-size: 0.32rem;color:#ffffff;width:3.8rem;height:0.85rem;background: #F68C1C;line-height: 0.32rem;font-family: font-family: Source Han Sans SC;" @click="handleSubmit" class="login-button">登录</a-button>
          </a-form-item>
        </a-form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {getItem,setItem} from "../../../utils/storage";

export default {
  name: "loginheader",
  props:{
    activekey:{
      type:Number,
      required:true
    }
  },
  data(){
    return{
      LoginModel:{
        username:'',
        password:''

      },
      form: this.$form.createForm(this, { name: 'dynamic_rule' }),

      panels:[
        {key:1,title:"中医药文化进校园"},
        {key:2,title:"课程内容"},
        {key:3,title:"校园风采"},
        {key:4,title:"关于我们"},
        {key:5,title:"政策驱动"}
      ]
    }
  },

  created(){


  },
  methods:{
    jump(key, action){

      if (key==1){

        this.$router.push('/newlogin');
      }else if (key==2){

        this.$router.push('/classcontent');
      }else if (key==3){
        this.$router.push('/schools');
      }else if (key==4){
        this.$router.push('/companyproduce');
      }
      else if (key==5){
        this.$router.push('/zhengcequdong');
      }

    },
    
    handleSubmit:async function(){


      let that=this;
      that.form.validateFields((err,values) => {
        that.LoginModel.username=values.username;
        that.LoginModel.password=values.password;
        if (!err||err==null) {
          that.$axios.post("api/Member/Login", that.LoginModel).then((res) => {
            if (res.data.Message=="SUCCESS"){
              console.log(res.data.Data)
              setItem("Token",res.data.Data.Token);
              setItem("UserID",res.data.Data.UserID);
              setItem("ClassName",res.data.Data.ClassName);
              setItem("GradeName",res.data.Data.GradeName);
          
              if(res.data.Data.OpenID==1){
                that.$router.push('/tearcheroom');
              }else{
                that.$router.push('/classdirectory');
              }
              
            }else{
              that.$message.error(res.data.Data);
            }
          });
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
.logo {

  display: flex;
  cursor: pointer;

  height: 2.8rem;
  justify-items: center;
img{
  height: 100%;
  width: 100%;
  vertical-align: middle;
  display: inline-block;
}
}

.top_content{

  display: flex;
  height: 1.51rem;


}
.leftcontent{
  display: flex;
  flex-direction: row;
  justify-items: center;
  

}
.contentdiv{

  flex-direction: row;
  display: flex;
  margin:0 0 0 4.58rem;
}
.wrpCls{
  height: 1.51rem;

  display: flex;
  justify-items: center;

  box-sizing: border-box;
}
.title{
  color: #3B8C35;
  text-align: center;
  font-weight: bold;
  font-size: 0.66rem;
  padding-left: 0.32rem;

}
::v-deep .ant-input{
  border-color: #6DAB68;
  border-radius: 0.5rem;
  font-size: 0.30rem;
  color: #333333;
  height: 0.81rem;
  width: 5.25rem;
  background: #ffffff;
  font-family: Source Han Sans SC;
}
::v-deep .ant-btn{
  border: 0;
}

::v-deep .ant-btn:hover, .ant-btn:focus{
  border: 0;
}


::v-deep .ant-input-affix-wrapper{
  font-size: 0.5rem;
}
::v-deep .ant-input-suffix{
  top:55%;
}
::v-deep .tabss .ant-tabs-tab{
  color: #1a1a1a;

}
.login-form{

  display: flex;
  background: #EEFFFE;
  height: 100%;

  padding-top: 0.62rem;
  flex-direction: row;

}
::v-deep .ant-tabs-tab{

color: #333333!important;
font-weight: bold;
  text-align: center;
  font-size: 0.66rem;

font-family:  jiangxizhuokai;
}
::v-deep .ant-tabs-tab-active{
  color: #3B8C35!important;

}
::v-deep .ant-tabs-ink-bar
{font-family: Source Han Sans SC;




  background-color: #3B8C35!important;
  border-radius: 0.52rem;
}




</style>